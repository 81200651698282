import React, { useState, useEffect } from "react";

import * as styled from "./style";
import MemberContactInfoIcon from "../../../assets/images/member-contact-info.svg";
import accordianClose from "../../../assets/images/accordianClose.png";
import accordianOpen from "../../../assets/images/accordianOpen.png";
import WebLinkIcon from "../../../assets/images/web-link-icon.svg";
import ContactIcon from "../../../assets/images/phone-icon.svg";
import EmailIcon from "../../../assets/images/email-icon.svg";
import searchResources from "../../../config/content/searchPage";

const MemberContactInfo = (props) => {
  const [showContactInfo, setShowContactInfo] = useState(false);

  useEffect(() => {
    if (props.expanded) {
      setShowContactInfo(true)
    }
  }, [])

  const { searchInput, zeroSearchResultMemberDetails } = props;
  const {search: {
    product_placrHolder,
    products_txt
  } } = searchResources;

  const memberContactInfo = (data) => {
    let updatedData = [];
    if (
      searchInput.userInput.userSearchInput.toLowerCase().includes("dupixent")
    ) {
      const dupixentData = [];
      const nonDupixentData = [];
      data.map((dt) =>
        dt.companyName.toLowerCase().includes("regeneron")
          ? dupixentData.push(dt)
          : nonDupixentData.push(dt)
      );
      updatedData = [...dupixentData, ...nonDupixentData];
    } else {
      updatedData = [...data];
    }
    return updatedData.map((ele, index) => {
      return (
        <div key={index} className="patient-mir-info">
          <div className="member-name-details">
            <span
              dangerouslySetInnerHTML={{ __html: `${product_placrHolder} ` }}
            ></span>
            <span
              dangerouslySetInnerHTML={{
                __html: `<b>${ele.companyName} </b>${products_txt} visit:`
              }}
            ></span>
          </div>
          <styled.MedicalLinkWrapper
            className="member-link"
            onClick={() => window.open(`/redirect?url=${ele.medicalInfo.medicalInformationLink}`, "_blank")}
          >
            <img
              src={WebLinkIcon}
              alt="web-link"
              className="member-link-icon"
            />
            <strong>{ele.medicalInfo.linkLabel || ele.medicalInfo.medicalInformationLink }</strong>
          </styled.MedicalLinkWrapper>
          {ele.medicalInfo.contactNumber && ele.medicalInfo.contactNumber.length > 0 && (
            <div className="member-link">
              <img
                src={ContactIcon}
                alt="contact-number"
                className="member-contact-icon"
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: `<a href="tel://${ele.medicalInfo.contactNumber}"><strong>${ele.medicalInfo.contactNumber}</strong></a> `
                }}
              ></span>
            </div>
          )}
          {ele.medicalInfo.email && ele.medicalInfo.email.length > 0 && (
            <div className="member-link">
              <img
                src={EmailIcon}
                alt="email"
                className="member-contact-icon"
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: `<a href="mailto:${ele.medicalInfo.email}"><strong>${ele.medicalInfo.email}</strong></a> `
                }}
              ></span>
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <styled.ResultsContainer>
      <styled.SectionTitle>
        <img src={MemberContactInfoIcon} alt="member-contact-info" className="member-contact-icon" /><span>Manufacturer Contact Info</span>
        <img src={showContactInfo ? accordianClose : accordianOpen} onClick={() => setShowContactInfo(prevState => !prevState)} className="expand-close-icon" />
      </styled.SectionTitle>
      <styled.NoResultWrapper showContactInfo={showContactInfo}>
        {memberContactInfo(zeroSearchResultMemberDetails)}
      </styled.NoResultWrapper>
    </styled.ResultsContainer>
  );
}

export default MemberContactInfo;