import CloseIcon from "../../assets/images/help_close.png";

export default {
  "helpTextDesktop": "?",
  "helpTextMobile": "Help ?",
  "tooltipContent": `<ul>
        <li>
          Your search will retrieve Prescribing Information powered by Datapharm.
        </li>
        <li>
          DataPharm, is a leading medicines information solution provider.To access more information about Datapharm go
          to <a href="https://www.datapharm.com">www.datapharm.com</a>
        </li>
      </ul>`,
  "closeIcon": CloseIcon,
  "youtubelink": "https://www.youtube.com/watch?v=u3TJHlBgPLM"
}
