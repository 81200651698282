import React, { useEffect, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

import playIcon from "../../assets/images/play.svg";
import pauseIcon from "../../assets/images/pause.svg";
import forwardIcon from "../../assets/images/fwd10.svg";
import rewindIcon from "../../assets/images/rev10.svg";
import closeIcon from "../../assets/images/close-white.png"
import * as styled from "./styles";

const CustomAudioPlayer = ({ audioData, onClosePlayer }) => {
  const { title, audioUrl, imageUrl } = audioData;
  const [fillWidth, setFillWidth] = useState("0");

  const changeFill = () => {

    const newWidth = document.getElementsByClassName("rhap_volume-indicator")[0].style.left;
    setFillWidth(`${100 - 13 - newWidth.split("%")[0]}%`)

  }

  useEffect(() => {
    const colorFill = document.getElementsByClassName("rhap_volume-bar");
    if (colorFill.length > 0) {
      colorFill[0].style.setProperty("--fill-width", `${fillWidth}`);
    }
  }, [fillWidth])

  useEffect(() => {
    // document.getElementsByTagName("body")[0].style.overflowY = "hidden"
  }, [])
  return (
    <styled.PlayerContainer>
      <img src={closeIcon} alt="close-player" className="close-icon" align="right" onClick={onClosePlayer} />
      <img src={imageUrl} alt="podcast-image" className="podcast-image" />
      <h5>{title}</h5>
      <AudioPlayer
        autoPlay
        src={audioUrl}
        onPlay={e => e}
        customAdditionalControls={[]}
        progressJumpSteps={{ backward: 10000,
          forward: 10000 }}
        customIcons={{
          play: <img src={playIcon} alt="play" />,
          pause: <img src={pauseIcon} alt="pause" />,
          forward: <img src={forwardIcon} alt="forward" />,
          rewind: <img src={rewindIcon} alt="rewind" />
        }}
        onVolumeChange={() => changeFill()}
      />
    </styled.PlayerContainer>
  );
}

export default CustomAudioPlayer;