import React, { useEffect, useState } from "react";
import _find from "lodash/find";
import _head from "lodash/head";
import _get from "lodash/get";
import { isMobileOnly } from "react-device-detect";

import * as styled from "./style";
import searchResources from "../../../config/content/searchPage";
import { endpoint } from "../../../config/app.config";
import NoResultsFound from "../../../components/no-results-found";
import PITitleIcon from "../../../assets/images/pi-icon.svg";
import accordianClose from "../../../assets/images/accordianClose.png";
import accordianOpen from "../../../assets/images/accordianOpen.png";
import expandIcon from "../../../assets/images/expand-icon.svg";
import collapseIcon from "../../../assets/images/collapse-icon.svg";
import DataPharmLogo from "../../../assets/images/datapharm-logo.png";

const PIResults = (props) => {
  const { piResults, onViewFullDocumentClick } = props;
  const [selectedPiResult, setSelectedPiResult] = useState(props.selectedDrugPI || piResults[0]);
  const [showDropdownList, setShowDropdownList] = useState(false);
  const [showPIResult, setShowPIResult] = useState(false);
  const [activeIndex, setActiveIndex] = useState([]);

  let piExpandTimeout;

  useEffect(() => {
    if (props.expanded) {
      props.showInformativePopups(true)
      setShowPIResult(true)
    }
    if (piResults.length > 0) {
      setSelectedPiResult(piResults[0]);
      props.setSelectedDrugPI(piResults[0])
    }
    const closeProductDropdown = (e) => {
      if (e.target.id == "selected-pi-product" || e.target.parentElement.id == "selected-pi-product") {
        setShowDropdownList(prevState => !prevState)
      } else if (e.target.id == "product-dropdown" || e.target.parentElement.id == "product-dropdown" || e.target.parentElement.parentElement.id == "product-dropdown") {
        return;
      } else if (e.target.id != "selected-pi-product" || e.target.parentElement.id != "selected-pi-product") {
        setShowDropdownList(false);
      }
    }
    document.body.addEventListener("click", closeProductDropdown);

    return () => {
      document.body.removeEventListener("click", closeProductDropdown);
      clearTimeout(piExpandTimeout);
    }
  }, [])

  const getSessionId = () => {
    let sessionId = localStorage.getItem("_sid");
    if (!sessionId) {
      sessionId = sessionStorage.getItem("_sid");
      if (!sessionId) {
        sessionId = "NA";
      }
    }
    return sessionId;
  };

  const onPIViewFullDocumentClick = () => {
    let matchedCompany = null;
    if (selectedPiResult) {
      matchedCompany = props.manufacturerDetails ? _find(props.manufacturerDetails.data,
        itm => (itm.companyName.includes(selectedPiResult.producer) || selectedPiResult.producer.includes(itm.companyName))) : null;
    }
    const companyId = selectedPiResult.highlightSource ? matchedCompany ? matchedCompany.urlTitle : "NA" : null;
    if (companyId) {
      onViewFullDocumentClick(false, selectedPiResult, selectedPiResult.genericDrug.toLowerCase());
    } else {
      onNonMemberCompanyClick(selectedPiResult)
    }
  };

  const onNonMemberCompanyClick = (item) => {
    const GADegrees = ["MD/DO", "NP/APN", "RPh/PharmD", "PA", "RN", "NE", "PT"];
    const { usersearchQuery } = props;
    const { role } = props;
    let degreeRole = role;
    if (role.toLowerCase().includes("patient") || role.toLowerCase().includes("consumer") || role.toLowerCase().includes("caregiver")) {
      degreeRole = "PT"
    }
    window.dataLayer = window.dataLayer || [];
    const { googleAnalytics: { piviewfulldoc_txt } } = searchResources;
    const sessionId = getSessionId();
    if (GADegrees.includes(degreeRole)) {
      window.dataLayer.push({
        event: "PhactMI Custom Event",
        phactmiEventData: {
          "eventCategory": degreeRole,
          "eventAction": piviewfulldoc_txt,
          "eventLabel": item.drug,
          "cd1": degreeRole,
          "cd2": piviewfulldoc_txt,
          "cd3": item.drug,
          "cd4": usersearchQuery,
          "cd5": item.producer,
          "cd8": "Null"
        }
      })
    }
    else {
      window.dataLayer.push({
        event: "PhactMI Custom Event",
        phactmiEventData: {
          "eventCategory": role,
          "eventAction": piviewfulldoc_txt,
          "eventLabel": item.drug,
          "cd1": "Other",
          "cd2": piviewfulldoc_txt,
          "cd3": item.drug,
          "cd4": usersearchQuery,
          "cd5": item.producer,
          "cd8": role
        }
      })
    }
    const pdfLink = `${endpoint}/api/search/spl/${item.setId}/download?viewonly=true&sessionId=${sessionId}`;
    window.open(pdfLink, "_blank");
  }

  const getNoResultConstants = (noResults, product) => {
    const { usersearchQuery } = props;
    const noResultConstants = { ...noResults };
    noResultConstants.no_product_txt = noResults.no_product_txt.replace("${product}", usersearchQuery);
    return (noResultConstants);
  }

  const onExpandCollapsePISection = (event, index, sectionName) => {
    const isExpanded = activeIndex.includes(index) ? true : false;
    setActiveIndex((prevState) => activeIndex.includes(index) ? [] : [index]);
    piExpandCollapseClick(event, isExpanded, selectedPiResult, index, sectionName)
  }

  const onMemberNameClick = () => {
    let matchedCompany = null;
    if (selectedPiResult) {
      matchedCompany = props.manufacturerDetails ?
        _find(props.manufacturerDetails.data, record => (_find(record.searchCompanyName, itm => (itm.toLowerCase().includes(selectedPiResult.producer.toLowerCase())))))
        : null;
    }
    const companyId = selectedPiResult.highlightSource ? matchedCompany ? matchedCompany.urlTitle : "NA" : null;
    props.onSourceClick(companyId)
  }

  const piExpandCollapseClick = (event, isExpanded, item, index, sectionName) => {
    piExpandTimeout = setTimeout(() => {
      if (index === 0 || index == 1) {
        const clickedDocument = document.getElementById("pi-title");
        clickedDocument.scrollIntoView();
      } else {
        const desiredIndex = index - 2;
        const clickedDocument = document.getElementById(`pi-section-title-${desiredIndex}`);
        clickedDocument.scrollIntoView();
      }
    }, 300);

    const GADegrees = ["MD/DO", "NP/APN", "RPh/PharmD", "PA", "RN", "NE", "PT"];
    const { role } = props;
    let degreeRole = role;
    if (role.toLowerCase().includes("patient") || role.toLowerCase().includes("consumer") || role.toLowerCase().includes("caregiver")) {
      degreeRole = "PT"
    }
    const { usersearchQuery } = props;
    const { drug } = item;
    const { googleAnalytics: { picollapseclick_txt, pisummarysectionexpandclick, pisummarysectioncollapseclick } } = searchResources;
    window.dataLayer = window.dataLayer || [];
    if (isExpanded) {
      if (GADegrees.includes(degreeRole)) {
        window.dataLayer.push({
          event: "PhactMI Custom Event",
          phactmiEventData: {
            "eventCategory": degreeRole,
            "eventAction": pisummarysectioncollapseclick,
            "eventLabel": drug,
            "cd1": degreeRole,
            "cd2": pisummarysectioncollapseclick,
            "cd3": drug,
            "cd4": usersearchQuery,
            "cd5": item.producer,
            "cd8": "Null",
            "cd15": sectionName
          }
        })
      }
      else {
        window.dataLayer.push({
          event: "PhactMI Custom Event",
          phactmiEventData: {
            "eventCategory": role,
            "eventAction": pisummarysectioncollapseclick,
            "eventLabel": drug,
            "cd1": "Other",
            "cd2": pisummarysectioncollapseclick,
            "cd3": drug,
            "cd4": usersearchQuery,
            "cd5": item.producer,
            "cd8": role,
            "cd15": sectionName
          }
        })
      }

    }
    else {
      if (GADegrees.includes(degreeRole)) {
        window.dataLayer.push({
          event: "PhactMI Custom Event",
          phactmiEventData: {
            "eventCategory": degreeRole,
            "eventAction": pisummarysectionexpandclick,
            "eventLabel": drug,
            "cd1": degreeRole,
            "cd2": pisummarysectionexpandclick,
            "cd3": drug,
            "cd4": usersearchQuery,
            "cd5": item.producer,
            "cd8": "Null",
            "cd15": sectionName
          }
        })
      }
      else {
        window.dataLayer.push({
          event: "PhactMI Custom Event",
          phactmiEventData: {
            "eventCategory": role,
            "eventAction": pisummarysectionexpandclick,
            "eventLabel": drug,
            "cd1": "Other",
            "cd2": pisummarysectionexpandclick,
            "cd3": drug,
            "cd4": usersearchQuery,
            "cd5": item.producer,
            "cd8": role,
            "cd15": sectionName
          }
        })
      }

    }
  }

  return (
    <styled.ResultsContainer>
      <styled.SectionTitle id="pi-tab"><img src={PITitleIcon} alt="srd-list" /><span>Prescribing Information ({piResults.length})</span>
        <img src={ showPIResult ? accordianClose : accordianOpen} onClick={() => {
          props.showInformativePopups(!showPIResult)
          setShowPIResult(prevState => !prevState) }} className="expand-close-icon" />
          </styled.SectionTitle>
          <styled.DataPharmContainer>
              <div class="line dashed"></div>
              <><span>Powered by</span> <img src={DataPharmLogo} alt="datapharm-logo" onClick={() => window.open("https://www.datapharm.com/resource-hub/datapharm-partners-phact-mi-enhance-medicines/")} /></>
          </styled.DataPharmContainer>
      <styled.DataContainer showPIResult={showPIResult}>
        {props.piResults.length === 0 &&
          <NoResultsFound
            userInput=""
            resources={getNoResultConstants(searchResources.noResults, props.searchInput.userInput)}
          >
          </NoResultsFound>}
        {props.piResults.length > 0 && <><styled.ProductResultDropdown>
          <styled.ProductDropdownSelectedValue id="selected-pi-product">
            <div><strong>{selectedPiResult.drug}</strong> ({selectedPiResult.genericDrug.toLowerCase()})</div>
            <img src={showDropdownList ? accordianClose : accordianOpen} alt={showDropdownList ? "close-dropdown" : "open-dropdown"} />
          </styled.ProductDropdownSelectedValue>
          {piResults.length > 0 && showDropdownList &&
          <styled.ProductResultDropdownList id="product-dropdown">{piResults.map(result => {
            return (<p key={result.setId} onClick={() => {
              setShowDropdownList(false);
              setActiveIndex([])
              props.setSelectedDrugPI(result)
              setSelectedPiResult(result) }}><strong>{result.drug}</strong> ({result.genericDrug.toLowerCase()})</p>)
          })}</styled.ProductResultDropdownList>}
        </styled.ProductResultDropdown>
        <styled.SelectedPIData>
          <styled.PIDataTitle id="pi-title" onClick={() => onPIViewFullDocumentClick()}><strong>
            {selectedPiResult.drug}</strong> ({selectedPiResult.genericDrug.toLowerCase()})
          </styled.PIDataTitle>
          {window.innerWidth >= 768 && <styled.PIDataSubTitle>FORM: <strong>{selectedPiResult.form}</strong></styled.PIDataSubTitle>}
          <styled.DataAccordionFooter>
            {window.innerWidth >= 768 && <styled.PIDataSubTitle style={{ margin: 0 }}>Source: <span className="source-link" onClick={onMemberNameClick}>
              {selectedPiResult.producer}</span>
            </styled.PIDataSubTitle>}
            <styled.ViewPiDocument id="viewDocument-btn-pi" onClick={() => onPIViewFullDocumentClick()}>
              VIEW FULL LABEL
            </styled.ViewPiDocument>
          </styled.DataAccordionFooter>
          <styled.PISectionsDetails usage="spl">
            {selectedPiResult && Object.keys(selectedPiResult.sections).map((section, index) => {
              return (
                <styled.PISection key={section + "" + index} >
                  <styled.PISectionTitle>
                    <styled.PISectionTitleText id={`pi-section-title-${index}`}>{section}</styled.PISectionTitleText>
                    <button type="button" onClick={(event) => onExpandCollapsePISection(event, index, section)}>
                      <img src={activeIndex.includes(index) ? collapseIcon : expandIcon} alt={index === activeIndex ? "collapse" : "expand"} />
                    </button>
                  </styled.PISectionTitle>
                  { activeIndex.includes(index) && <styled.AccordionExpandContent
                    dangerouslySetInnerHTML={{__html: selectedPiResult.sections[section]}}
                  />}
                </styled.PISection>
              )
            })}
          </styled.PISectionsDetails>
        </styled.SelectedPIData></>}
      </styled.DataContainer>
    </styled.ResultsContainer>
  )
}

export default PIResults;