import React, { Fragment, memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Joyride from "react-joyride";
import AutoSuggestSearch from "../auto-suggest-search";
import HelpTooltip from "../tooltip-search";
import PoweredByToolTip from "../../components/poweredby-tooltip";
import tooltipConstants from "../../config/content/tooltip";
import SearchItemPill from "../../components/searchitem-pill";
import * as styled from "./style";
import InformativePopups from "../informative-popups";
import DataPharmLogo from "../../assets/images/datapharm-logo.png";

const HomeSearch = props => {
  const { resources, onSearchClick, onSuggestionlistopen, tourRun, tourStatus, history, showDownloadAppPopup, isBrowserFlag } = props;
  const { heading_txt } = resources;
  const [searchData, searchDataFunction] = useState([]);
  const [error, updateErrorStatus] = useState(false);
  const [termError, updateTermError] = useState(false);
  const [ run, setRun ] = useState(false);
  const [ steps, setSteps ] = useState([
    {
      content: <InformativePopups stepNumber={1} totalSteps={2}>Looking for drug information about a specific product? Begin by entering the product here.</InformativePopups>,
      locale: { skip: <strong aria-label="skip">Later</strong>,
        back: <span aria-label="back">Previous</span>,
        next: <strong aria-label="next">Next</strong>,
        last: <strong aria-label="last">Got it</strong>},
      placement: "right",
      target: ".product-input",
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true
      },
      styles: {
        options: {
          width: 390
        }
      }
    },
    {
      content: <InformativePopups stepNumber={2} totalSteps={2}>Enter the query about the product here.</InformativePopups>,
      locale: { skip: <span aria-label="skip">Later</span>,
        back: <span aria-label="back">Previous</span>,
        next: <strong aria-label="next">Next</strong>,
        last: <strong aria-label="last">Got it</strong>},
      placement: "left",
      target: ".term-input",
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true
      },
      styles: {
        options: {
          width: 390
        }
      }
    }
  ])

  useEffect(() => {
    const firstLogin = JSON.parse(localStorage.getItem("firstLogin"));
    const downloadAppPopup = JSON.parse(localStorage.getItem("downloadAppPopup"))
    let timeout;
    if (!firstLogin) {
      if (window.innerWidth < 768 ) {
        setSteps(prevState => {
          return [
            {
              content: <InformativePopups stepNumber={1} totalSteps={2}>
                Looking for drug information about a specific product? Begin by entering the product here.
              </InformativePopups>,
              locale: { skip: <strong aria-label="skip">Later</strong>,
                back: <span aria-label="back">Previous</span>,
                next: <strong aria-label="next">Next</strong>,
                last: <strong aria-label="last">Got it</strong>},
              placement: "right",
              target: ".product-input",
              disableBeacon: true,
              floaterProps: {
                disableAnimation: true
              },
              styles: {
                options: {
                  width: 390
                }
              }
            },
            {
              content: <InformativePopups stepNumber={2} totalSteps={2}>Enter the query about the product here.</InformativePopups>,
              locale: { skip: <span aria-label="skip">Later</span>,
                back: <span aria-label="back">Previous</span>,
                next: <strong aria-label="next">Next</strong>,
                last: <strong aria-label="last">Got it</strong>},
              placement: "left",
              target: ".term-input-mobile",
              disableBeacon: true,
              floaterProps: {
                disableAnimation: true
              },
              styles: {
                options: {
                  width: 390
                }
              }
            }
          ]
        })
        if (downloadAppPopup && isBrowserFlag) {
          timeout = setTimeout(() => {
            setRun(true)
          }, 3000);
        } else if (!isBrowserFlag) {
          timeout = setTimeout(() => {
            setRun(true)
          }, 3000);
        } else {
          timeout = setTimeout(() => {
            setRun(false)
          }, 3000);
        }
      } else if ( window.innerWidth > 768 && window.innerWidth < 1024 ) {
        if (downloadAppPopup && isBrowserFlag) {
          timeout = setTimeout(() => {
            setRun(true)
          }, 3000);
        } else if (!isBrowserFlag) {
          timeout = setTimeout(() => {
            setRun(true)
          }, 3000);
        } else {
          timeout = setTimeout(() => {
            setRun(false)
          }, 3000);
        }
      } else {
        timeout = setTimeout(() => {
          setRun(true)
        }, 3000);
      }
    } else {
      setRun(false)
    }

    return () => clearTimeout(timeout)
  }, [showDownloadAppPopup, isBrowserFlag])

  const searchDataUpdate = (val) => {
    searchDataFunction([...val]);
    if (val && val.length > 0) {
      updateErrorStatus(false);
    }
  }
  const checkError = (val) => {
    updateErrorStatus(val)
  }

  const checkTermError = (val) => {
    updateTermError(val)
  }

  const handleJoyrideCallback = (data) => {
    const { index, status, size } = data;
    if (index !== 0 ) {
      const previousButton = document.querySelector("[title='Previous']");
      if (previousButton) {
        previousButton.parentNode.style.justifyContent = "space-between";
        previousButton.style.marginLeft = "0";
        previousButton.parentNode.firstChild.style.display = "none"
      }
    }
    if (status === "skipped") {
      setRun(false)
    } else if (status === "finished") {
      const firstLogin = {
        isFirst: true
      }
      localStorage.setItem("firstLogin", JSON.stringify(firstLogin))
    }
  }

  const { error_logo, error_logo_alt, error_message, error_message_search_term } = resources;
  return (
    <Fragment>
      {/* <Joyride
        disableOverlayClose
        callback={handleJoyrideCallback}
        showSkipButton
        continuous
        disableScrollParentFix={false}
        disableScrolling
        hideCloseButton={false}
        run={run}
        steps={steps}
        styles={{
          options: {
            zIndex: 10000
          }
        }}
      /> */}
      <styled.HomeSearchConatiner className="home-search">
        <styled.SearchHeading>{heading_txt}</styled.SearchHeading>
        <styled.DataPharmContainer>
          <p>In Partnership with</p>
          <img src={DataPharmLogo} alt="datapharm-logo" />
        </styled.DataPharmContainer>
        <styled.SearchCard>
          <styled.SearchSection>
            <styled.SearchWrapper>
              <styled.SearchContainer className="searchAccordian" tourRun={tourRun}>
                <AutoSuggestSearch
                  resources={resources}
                  onSearchClick={(val, latestSearchData, keywordInput) => onSearchClick(val, latestSearchData, keywordInput)}
                  fromHomePage={true}
                  tourRun={tourRun}
                  tourStatus={tourStatus}
                  onSuggestionlistopen={val => onSuggestionlistopen(val)}
                  searchDataUpdate={(val) => searchDataUpdate(val)}
                  searchQueryData={searchData}
                  checkError={(val) => checkError(val)}
                  checkTermError={(val) => checkTermError(val)}
                  history={history}
                  error={error}
                />
              </styled.SearchContainer>
              <styled.TooltipSection>
                {
                  (error || termError) &&
                    <styled.ErrorWrapperMobile>
                      <img src={error_logo} alt={error_logo_alt}></img>
                      <p>{termError ? error_message_search_term : error_message}</p>
                    </styled.ErrorWrapperMobile>
                }
                <styled.ToolTipWrapperMobile>
                  <styled.HelpToolTipSection>
                    <HelpTooltip resources={tooltipConstants} history={history} searchError={error}></HelpTooltip>
                  </styled.HelpToolTipSection>
                </styled.ToolTipWrapperMobile>
              </styled.TooltipSection>
            </styled.SearchWrapper>
            <styled.SearchPillWrapper>
              <styled.PillWrapper error={error}>
              </styled.PillWrapper>
            </styled.SearchPillWrapper>
          </styled.SearchSection>
        </styled.SearchCard>
      </styled.HomeSearchConatiner>
    </Fragment>
  )
};

HomeSearch.propTypes = {
  resources: PropTypes.shape({
    heading_txt: PropTypes.string,
    Placeholder_txt: PropTypes.string,
    button_txt: PropTypes.string,
    icon: PropTypes.string
  }),
  onSearchClick: PropTypes.func
};

export default memo(HomeSearch);
