import React, { Component } from "react";
import axios from "axios";
import PdfViewer from "../../components/pdf-viewer";
import { endpoint } from "../../config/app.config";
import { isMobile, isAndroid } from "react-device-detect"

class SrdViewer extends Component {

    state = {
      keywords: null,
      id: null,
      query: null,
      pdbase64: null,
      err: false
    }

  /**
   * Function Return Session ID
   * @returns {Number} Session Id
   */
  getSessionId = () => {
    let sessionId = localStorage.getItem("_sid");
    if (!sessionId) {
      sessionId = sessionStorage.getItem("_sid");
      if (!sessionId) {
        sessionId = "NA";
      }
    }
    return sessionId;
  }

    getQueryParams = () => {
      return decodeURI(window.location.search)
        .replace("?", "")
        .split("&")
        .map(param => param.split("="))
        .reduce((values, [ key, value ]) => {
          values[ key ] = value
          return values
        }, {})
    }

    // getPdfUrl = () => {
    //   const { id, query, keywords } = this.state;
    //   return encodeURI(`${endpoint}/api/search/substances/${id}?query=${query}`);
    // }

    componentDidMount() {
      const { id, query, term, sourceDisplayName } = this.getQueryParams();

      // document.getElementsByClassName("container").forEach((elem) => {
      //   elem.parentElement.style.display = "none"
      // });
      const sessionId = this.getSessionId();
      if (!id || !query || !term || !sourceDisplayName || !sessionId) {
        this.setState({err: true})
        return;
      }

      return axios.post(`${endpoint}/api/search/substances/${id}/metadata?query=${query}&term=${term}&sessionId=${sessionId}`, {
        docId:id,
        action:"document",
        // application: !isMobile ? "web-browser" : isAndroid ? "android-app" : "ios-app",
        application: "web-browser",
        url:`${endpoint}${this.props.location.pathname}${this.props.location.search}`,
        product:query.trim(),
        searchTerm:term.trim(),
        sourceDisplayName,
        sessionId
      })
        .then(({ data }) => {
          if (data.highlight.text) {
            const keywords = data.highlight.text;
            const {base64Pdf} = data;
            this.setState(() => {
              return { keywords,
                id,
                query,
                base64Pdf,
                err: false }
            })
          }
        })
        .catch((error) => {
          this.setState(() => {
            return { id,
              query }
          })
        })
    }

    render() {
      const { id, keywords, base64Pdf } = this.state
      // const url = this.getPdfUrl();
      return <React.Fragment>
        {id ? <PdfViewer base64={base64Pdf} keywords={keywords} /> : this.state.err ?
          <div style={{ backgroundColor: "white",
            marginTop: "80px",
            height: "100vh"}}>Something went wrong.Please contact support.</div> : <div style={{ backgroundColor: "white",
            marginTop: "80px",
            height: "100vh"}}>Loading...</div>}
      </React.Fragment>
    }
}

export default SrdViewer;
