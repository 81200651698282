import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange } from "../../common/styles/breakpoints";

export const CloseButton = styled.span`
  display: none;
  width: 15px;
  height: 15px;
  background: ${colors.white};
  position: absolute;
  top: 20px;
  right: 25px;
  background: ${props => (`url(${props.bgImage}) no-repeat 0 0`)};
  opacity: 0.5;
  cursor: pointer;
  @media ${tabletRange} {
    display: block;
    top: 15px;
    right: 10px;
  }
  @media ${mobileRange} {
    display: block;
    top: 15px;
    right: 15px;
  } 
  @media ${ipadProRange} {
    display: block;
  } 
`;

export const Tooltip = styled.div`
  width: 380px;
  background-color: ${colors.aqua_haze};
  border: solid 1px ${colors.sherpa_blue};
  border-radius: 4px;
  padding: 30px 15px 10px;
  position: absolute;
  z-index: 100;
  top: ${ props => ( props.top + 20 + "px" )};
  left: ${ props => ( props.left - 328 + "px")};
  font-family: Lato;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.46;
  text-align: left;
  color: ${colors.tundora_approx};
  display: none;
  ul {
    padding-left: 15px;
    margin: 0;
  }
  ul li {
    padding-bottom: 14px;
    @media ${mobileRange} {
      padding-bottom: 15px;
    }
  }

  ::after {
    content: "";
    position: absolute;
    bottom: 99%;
    right: 24px;
    margin-left: -4px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent ${colors.aqua_haze} transparent;
    @media ${mobileRange} {
      right: 7%;
    }
  }

  ::before {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 24px;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent ${colors.sherpa_blue} transparent;
    @media ${mobileRange} {
      right: 7%;
    }
  }
  @media ${tabletRange} {
    width: 375px;
    padding: 20px 30px 10px;
  }
  @media ${ipadProRange} {
    width: 375px;
    padding: 20px 30px 10px;
  }
  @media ${mobileRange} {
    max-width: 300px;
    padding: 20px 30px 10px 20px;
    top: ${ props => ( props.top + 20 + "px")};
    right: 1%;
    left: auto;
  }
`;

export const HelpButton = styled.div`
  width: 30px;
  height: 30px;
  justify-content: center;
  display: flex;
  border: solid 1px ${colors.sherpa_blue};
  background-color: ${colors.aqua_haze};
  border-radius: 50%;
  font-family: ArialRoundedMT;
  font-size: 16px;
  font-weight: bold;
  color: ${colors.sherpa_blue};
  align-items: center;
  :hover ${Tooltip} {
    display: block;
  }

  @media ${tabletRange} {
    width: 25px;
    height: 25px;
  }

  @media ${mobileRange} {
    width: 56px;
    height: 26px;
    border-radius: 50px;
    background: none;
    border: none;
    text-decoration: underline;
    color: ${colors.bondi_blue}
  }
`;

export const HelpText = styled.span`
  cursor: default;
  @media ${mobileRange} {
    display: none;
  }
`;

export const HelpTextMobile = styled.span`
  display: none;
  cursor: default;
  @media ${mobileRange} {
    display: block;
  }
`;

export const ToolTipContent = styled.span`
  span {
    color: ${colors.light_solid_blue};
    cursor: pointer;
  }
  span:hover {
    color: ${colors.dark_blue};
    text-decoration: underline;
  }

  a {
    color: ${colors.bondi_blue};
    text-decoration: underline;
  }
`;
