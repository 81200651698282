import styled from "styled-components";
import { colors } from "../../common/styles/variables";
import { mobileRange, tabletRange } from "../../common/styles/breakpoints";

export const PlayerContainer = styled.div`

  div[aria-label="Audio player"] {
    background-color: rgb(0, 66, 81);
  }

  .rhap_time {
    color: ${colors.white}
  }
  .rhap_volume-button {
    color: ${colors.white}
  }
  .rhap_volume-bar, .rhap_volume-indicator, .rhap_progress-filled, .rhap_progress-indicator {
    background-color: #1CB19B;
  }

  .rhap_volume-bar, {
    position: relative;
  }
  .rhap_volume-bar: after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: var(--fill-width, 0%);
    height: 100%;
    background-color: white;
  }

  .rhap_container {
    @media ${mobileRange} {
      padding: 10px 10px 10px 4px;
    }
  }

  .rhap_volume-controls {
    @media ${mobileRange} {
      position: absolute;
      bottom: 385px;
      left: 0;
      width: 120px;
      display: none;
    }
  }
  .rhap_main-controls {
    @media ${mobileRange} {
      width: 100%;
    }
  }
 

  .rhap_button-clear {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 18px;

    @media ${mobileRange} {
      margin: 0 12px 0 0;
    }
  }

  h5 {
    color: ${colors.white};
    font-size: 24px;
    margin-top: 8px;
    text-align: center;

    @media ${mobileRange} {
      font-size: 16px;
      margin: 8px 16px 0;
    }
  }

  .podcast-image {
    width: 100%;
    height: 467px;
    object-fit: contain;

    @media ${mobileRange} {
      height: 250px;
    } 

    @media ${tabletRange} {
      height: 350px;
    }
  }

  .close-icon {
    width: 20px;
    height: 20px;
    margin-bottom: 16px;
    cursor: pointer;

    @media ${mobileRange} {
      width: 15px;
      height: 15px;
      margin-right: 12px;
    }
  }
`;