import styled from "styled-components";
import { colors, containerMaxWidth } from "../../common/styles/variables";
import { mobileRange, tabletRange, ipadProRange, minLaptop } from "../../common/styles/breakpoints";

export const HomePage = styled.div`
  width: 100%;
  min-height: 700px;
  color: ${colors.sherpa_blue};
  background: transparent;
  font-size: 20px;
  position: relative;
  & .container {
    padding: 0;
    max-width: ${containerMaxWidth};
  }
  & .popup-close-icon {
    cursor: pointer;
    text-align: end;
  }
  @media ${mobileRange} {
    padding-top: 84px;
  }
`;

export const HeaderSection = styled.div`
  @media ${mobileRange} {
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  @media ${tabletRange} {
    margin: 0 40px;
  }
  @media ${ipadProRange} {
    margin: 0 30px;
  }
`;

export const MainHeading = styled.div`
  font-size: 34px;
  font-weight: 300;
  line-height: 1.35;
  letter-spacing: -0.44px;
  color: ${colors.sherpa_blue};
  line-height: 1.35;
  margin-top: 30px;
  letter-spacing: -0.44px;
  h1 {
    margin: 0;
    font-size: 30px;
    font-weight: 300;
    line-height: 1.35;
    @media ${tabletRange} {
      font-size: 24px;
      line-height: 1.38;
    }
    @media ${mobileRange} {
      font-size: 24px;
      line-height: 1.54;
    }
  }
  span {
    font-weight: normal;
  }
  @media ${mobileRange} {
    font-size: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.54;
    letter-spacing: -0.31px;
    div {
      width: 93%;
    }
    p {
      display: none;
    }
  }
  @media ${tabletRange} {
    font-size: 24px;
    line-height: 1.38;
    letter-spacing: -0.31px;
    margin-top: 54px;
    margin-bottom: 30px;
    div {
      width: 98%;
    }
    p {
      display: none;
    }
  }
`;

export const MainHeadingTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const DataPharmContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  img {
    width: 175px;
    height: 58px;
    cursor: pointer;
  }
  p {
    color: ${colors.gray};
    font-size: 16px;
    font-weight: 400;
    margin: 0;

    @media ${tabletRange} {
      display: initial;
    }
  }

  @media ${mobileRange} {
    display: none;
  }

  @media ${tabletRange} {
    width: 40% !important;
  }
`;

export const SubHeading = styled.div`
  width: 78%; 
  @media ${mobileRange} {
    display: none;
  }
`;

export const MainSection = styled.div`
  background-color: ${colors.white};
  margin-top: 10px;
  color: ${colors.scorpion}
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  @media ${tabletRange} {
    margin-top: 15px;
  }
  @media ${mobileRange} {
    box-shadow: none;
    width: 93%;
    margin: auto;
  }
`;

export const NewsSection = styled.div`
  & .corporate {
    padding-top: 51px;
    padding-bottom: 66px;
    background: ${colors.catskill_white};
    @media ${mobileRange} {
      display: flex;
      flex-direction: column;
      padding-top: 0;
      padding-bottom: 40px;
      background: none;
    }
    @media ${tabletRange} {
      padding-top: 47px;
      padding-bottom: 40px;
    }
    @media ${ipadProRange} {
      padding-top: 42px;
      padding-bottom: 57px;
    }
  }
  & .row {
    margin: 0;
    @media ${mobileRange} {
      margin-top: 30px;
    }
  }
  & .corporate-partnership {
    @media ${mobileRange} {
      margin-bottom: 0;
      padding: 0;
    }
    @media ${tabletRange} {
      padding: 0;
    }
  }
  @media ${mobileRange} {
    .news > after {
      border-bottom: 1px solid ${colors.gray};
    }
  }
`;

export const Video = styled.div`
  position absolute;
  z-index: 101
  height: auto;
  width: auto;
  iframe {
    width: 862px;
    height: 500px;
    z-index: 1000;
    @media ${mobileRange} {
      width: 270px;
      height: 312px;
    }
    @media ${tabletRange} {
      width: 562px;
      height: 312px;
    }
    @media ${ipadProRange} {
      width: 762px;
      height: 410px;
    }
  }
`;

export const TourVideoWrapper = styled.div`
  position: absolute;
  top: 5%;
  left: 0;
  right: 0;
  margin: auto;
  width: 900px;
  height: 590px;
  z-index: 101;
  background: ${colors.white};
  padding: 12px 19px 19px;
  border-radius: 10px;
  @media ${mobileRange} {
    width: 290px;
    height: 400px;
    padding: 12px 10px 19px;
  }
  @media ${tabletRange} {
    width: 600px;
    height: 400px;
  }
  @media ${ipadProRange} {
    width: 800px;
    height: 500px;
  }
`;

export const CloseButton = styled.div`
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: ${colors.medium_dove_gray};
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    padding-right: 5px;
    font-size: 14px;
    font-size: 20px;
    color: ${colors.white};
    margin-bottom: 4px;
  }
  img {
    width: 15px;  
    height: 15px;
  }
  .closeWhite {
    display: none;
  }
  .closeGreen {
    display: block;
  }
  :hover {
    background: ${colors.bondi_green};
    .closeWhite {
      display: block;
    }
    .closeGreen {
      display: none;
    }
  }
`;

export const VideoHeading = styled.div`
  display: flex;
  align-items: center;
  h2 {
    font-size: 24px;
    width: 90%;
    padding-left: 12px;
  }
  .phactmi_logo {
    width: 30px;
    height: 30px;
  }
`;

export const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 10%;
  z-index: 101;
  padding-bottom: 19px;
  @media ${mobileRange} {
    width: 14%;
  }
`;

export const CodeOfPracticeButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media ${mobileRange} {
    padding: 0 20px;
  }
  button {
    outline: none;
    background: ${colors.bondi_blue};
    color: ${colors.white};
    border: solid 1px ${colors.bondi_blue};
    border-radius: 8px;
    padding: 5px 30px;
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: bold;
    span {
      font-size: 26px;
      margin-left: 9px;
      @media ${mobileRange} {
        font-size: 22px;
      }
    }
    @media ${mobileRange} {
      font-size: 14px;
      padding: 8px 26px;
      letter-spacing: 0.28px;
    }
    @media ${tabletRange} {
      font-size: 12px;
      padding: 5px 26px;
    }
  }
`;

export const ShowDbSection = styled.div`
  display: flex;
  padding: 40px 25px 60px;
  @media ${mobileRange} {
    flex-direction: column;
    margin-top: 40px;
    padding: 0;
  }
`;

export const DBWrapper = styled.div`
  width: 50%;
  border: 1px solid ${colors.alto};
  border-radius: 10px;
  text-align: center;
  margin: 0 20px;
  padding: 31px 28px 40px;
  @media ${tabletRange} {
    padding: 20px 20px 28px;
    margin: 0 11px;
  }
  @media ${mobileRange} {
    width: 100%;
    margin: 0 0 20px;
    padding: 16px 20px 28px;
    display: none;
  }
  .searchDbLogo {
    margin-bottom: 39px;
    @media ${tabletRange} {
      margin-bottom: 29px;
    }
    img {
      width: 182px;
      @media ${tabletRange} {
        width: 155px;
      }
    }
  }
  .humanExpertLogo {
    margin-bottom: 40px;
    @media ${tabletRange} {
      margin-bottom: 29px;
    }
    img {
      width: 156px;
      @media ${tabletRange} {
        width: 133px;
      }
    }
  }
  ul {
    text-align: left;
    height: 90px;
    color: ${colors.tundora};
    font-size: 16px;
    padding: 0 0 0 12px;
    margin-bottom: 0;
    @media ${tabletRange} {
      font-size: 14px;
    }
    @media ${mobileRange} {
      padding-top: 10px;
      font-size: 14px;
    }
  }
`;

export const DBTitle = styled.div`
  font-size: 28px;
  font-weight: 500;
  color: ${colors.sherpa_blue};
  padding: 0 0 21px;
  @media ${tabletRange} {
    font-size: 22px;
  }
  @media ${mobileRange} {
    font-size: 26px;
  }
`;

export const DBSubTitle = styled.div`
  height: 95px;
  font-size: 18px;
  font-weight: 600;
  text-align: ${props => props.leftAlign ? "left" : "center"};
  @media ${tabletRange} {
    font-size: 16px;
  }
  @media ${mobileRange} {
    height: 55px;
    font-size: 16px;
  }
`;

export const ExpertContent = styled.div`
  font-size: 16px;
  height: 90px;
  color: ${colors.tundora};
  @media ${tabletRange} {
    font-size: 14px;
  }
  @media ${mobileRange} {
    font-size: 14px;
    height: 90px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 16px;
  @media ${mobileRange} {
    margin-top: 0;
  }
  button {
    background: ${colors.sherpa_blue};
    padding: 15px 50px 14px;
    border-radius: 8px;
    color: ${colors.white};
    outline: none;
    border: none;
    cursor: pointer;  
    font-size: 14px;
    @media ${tabletRange} {
      font-size: 12px;
      padding: 11px 22px;
    }
    @media ${mobileRange} {
      font-size: 12px;
      padding: 11px 22px;
    }
  }
  .searchDbButton {
    @media ${tabletRange} {
      font-size: 12px;
      padding: 11px 38px;
    }
    @media ${mobileRange} {
      font-size: 12px;
      padding: 11px 38px;
    }
`;

export const DrugLabelWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    @media ${mobileRange} {
      display: none;
    }
    img {
      width: 46px;
      @media ${tabletRange} {
        width: 34px;
      }
    }
    span {
      color: ${colors.sherpa_blue};
      font-size: 38px;
      font-weight: 500;
      padding-left: 10px;
      @media ${tabletRange} {
        font-size: 32px;
      }
    }
`;

export const DrugLabelWrapperMobile = styled.div`
    display: none;
    @media ${mobileRange} {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 34px;
      }
      div {
        font-weight: 700;
        color: ${colors.sherpa_blue};
        font-size: 28px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      span {
        font-weight: 700;
      }
    }
`;

export const DbSectionAccordion = styled.div`
    display: none;
    @media ${mobileRange} {
      display: block;
    }
`;

export const AccordionTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 61px;
      height: 45px;
      margin-right: 12px;
    }
    h5 {
      font-size: 16px;
      font-weight: bold;
      color: #000;
    }
    p {
      font-size: 14px;
      margin: 0;
    }
`;

export const AccordionContent = styled.div`
    margin-left: 73px;
    font-size: 12px;
    text-align: left;
    ul {
      margin: 0;
    }
    p {
      margin: 0
      margin-top: 6px;
    }
    button {
      color: #00B59A;
      font-size: 12px;
      text-align: left;
      padding: 0;
      font-weight: 700;
      border: none;
      border-bottom: 1px solid #00B59A;
      background: white;
      margin-top: 10px;
    }
`;

export const DownloadAppPopupContent = styled.div`
    text-align: center;
    h4 {
      font-size: 16px;
      font-weight: 700;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 24px;
    }
    .android-app-link,  .ios-app-link{
      cursor: pointer;
    }
`;
