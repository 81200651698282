import React, { Component, memo } from "react";
import PropTypes from "prop-types";
import * as styled from "./style";

/**
 * Tooltip component
 *
 * @class HelpToolTip
 * @extends {Component}
 * @exports HelpToolTip
 */
class HelpToolTip extends Component {

  state = {
    leftPositionTooltip: "50%",
    topPositionTooltip: "112%"
  }

  componentDidMount() {
    this.changeToolTipPosition();
    window.addEventListener("resize", this.changeToolTipPosition);
    window.addEventListener("touchstart", this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.changeToolTipPosition);
    window.removeEventListener("touchstart", this.handleClickOutside);
  }

  /**
   * @param  {Object} event Click event
   * @returns {undefined}
   */
  handleClickOutside= (event) => {
    if (!this.helpText.contains(event.target)) {
      if ( ("ontouchstart" in window) && this.toolTip && this.toolTip.style.display !== "none" && this.toolTip.style.display !== "" ) {
        this.toolTip.style.display = "none";
      }
    }
  }

  /**
   * Change position of tooltip relative to helptext.
   * @returns {undefined}
   */
  changeToolTipPosition = () => {
    if (this.helpText) {
      this.setState({
        leftPositionTooltip: this.helpText.offsetLeft,
        topPositionTooltip: this.helpText.offsetTop + this.helpText.offsetHeight
      });
    }
  }

  /**
   * Display or hide tooltip for touch devices.
   * @param  {Event} e - Event created on click.
   * @returns {undefined}
   */
  onCloseTooltip = (e) => {
    if ( "ontouchstart" in window ) {
      this.toolTip.style.display = (this.toolTip.style.display === "block") ? "none" : "block";
      e.stopPropagation();
    }
  }

  /**
   * Anchor click handler function
   * @param {Event} e event
   * @returns {undefined}
   */
  handleHrefClick = (e) => {
    const { youtubelink } = this.props.resources;
    e.preventDefault();
    if (e.target.href) {
      const isNewUser = (localStorage.getItem("newuser"));
      if (isNewUser) {
        if (navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)) {
          //DO Nothing
        }
        else {
          if (window.location.pathname === "/home" || window.location.pathname === "/") {
            window.location.reload();
          }
          else {
            this.props.history.push("/home");
          }
          localStorage.removeItem("newuser");
          const tourRunning = true;
          sessionStorage.setItem("tourRunning", JSON.stringify(tourRunning));
        }
      }
      else {
        if (navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)) {
          window.open(youtubelink);
        }
      }
      //window.open(youtubelink);
    }
  }

  render() {
    const { leftPositionTooltip, topPositionTooltip } = this.state;
    const { helpTextDesktop, helpTextMobile, tooltipContent, closeIcon } = this.props.resources;
    const { searchError } = this.props;
    return (
      <styled.HelpButton ref={elm => { this.helpText = elm }} onTouchEnd={this.onCloseTooltip}>
        <styled.HelpText> { helpTextDesktop } </styled.HelpText>
        <styled.HelpTextMobile> { helpTextMobile } </styled.HelpTextMobile>
        <styled.Tooltip left={leftPositionTooltip} top={searchError ? topPositionTooltip + 30 : topPositionTooltip}
          ref={elm => { this.toolTip = elm }} onClick={(e) => { e.stopPropagation(); }}>
          <styled.CloseButton bgImage={closeIcon} onTouchEnd={this.onCloseTooltip}></styled.CloseButton>
          <styled.ToolTipContent dangerouslySetInnerHTML={{ __html: tooltipContent }}
            onTouchEnd={(e) => { e.stopPropagation(); }}></styled.ToolTipContent>
        </styled.Tooltip>
      </styled.HelpButton>
    )
  }
}

HelpToolTip.propTypes = {
  resources: PropTypes.object
}

export default memo(HelpToolTip);
